<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Líneas Vehiculares</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Vehículos</li>
                  <li class="breadcrumb-item active">Líneas Vehiculares</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="
                          $store.getters.can('admin.lineasVehiculares.create')
                        "
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        Marca
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.marca"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="marca in listasForms.marcas"
                            :key="marca.numeracion"
                            :value="marca.numeracion"
                          >
                            {{ marca.descripcion }}
                          </option>
                        </select>
                      </th>
											<th>
                        Línea
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.linea"
                          @keyup="getIndex()"
                        />
                      </th>
											<th>
                        Tipo Combustible
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_combustible"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipo_combustible in listasForms.tipo_combustibles"
                            :key="tipo_combustible.numeracion"
                            :value="tipo_combustible.numeracion"
                          >
                            {{ tipo_combustible.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option value="1">Autorizado</option>
                          <option value="1">No Autorizado</option>
                        </select>
                      </th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="lineasVehicular in lineasVehiculares.data"
                      :key="lineasVehicular.id"
                    >
                      <td>{{ lineasVehicular.id }}</td>
                      <td>{{ lineasVehicular.nMarca }}</td>
                      <td>{{ lineasVehicular.linea }}</td>
                      <td>{{ lineasVehicular.nTipoCombustible }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            lineasVehicular.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{
                            lineasVehicular.estado == 1
                              ? "Autorizado"
                              : "No Autorizado"
                          }}
                        </span>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm bg-navy"
                          v-if="
                            $store.getters.can('admin.lineasVehiculares.show')
                          "
                          @click="edit(lineasVehicular)"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="lineasVehiculares.total">
                  <p>
                    Mostrando del <b>{{ lineasVehiculares.from }}</b> al
                    <b>{{ lineasVehiculares.to }}</b> de un total:
                    <b>{{ lineasVehiculares.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="lineasVehiculares"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import { required, minLength, between } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";

export default {
  name: "LineasVehicularesIndex",
  components: {
    pagination,
  },
  data() {
    return {
      filtros: {},
      listasForms: {
        marcas: [],
        tipo_combustibles: [],
        preguntas: [],
      },
      lineasVehiculares: {},
    };
  },
  validations: {},
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/lineasVehiculares?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.lineasVehiculares = response.data;
        });
    },

    create() {
      return this.$router.push({
        name: "/Admin/LineasVehicularesForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(linea) {
      return this.$router.push({
        name: "/Admin/LineasVehicularesForm",
        params: {
          accion: "Editar",
          data_edit: linea,
        },
      });
    },

    getListaMarca() {
      axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    getListaTipoCombustible() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tipo_combustibles = response.data;
      });
    },

    getListaPreguntas() {
      axios.get("/api/lista/146").then((response) => {
        this.listasForms.preguntas = response.data;
      });
    },
  },
  mounted() {
    this.getIndex();
		this.getListaMarca();
    this.getListaTipoCombustible();
    this.getListaPreguntas();
  },
};
</script>